import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { firstValueFrom } from 'rxjs';
import { ListaProdutos } from 'src/app/consts/produtos';
import { FormCategorias } from 'src/app/form-validations/form-categorias';
import { ClienteService } from 'src/app/services/cliente.service';
import { ConsultaProdutoService } from 'src/app/services/consulta-produto.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { SearchPageComponent } from 'src/app/shared/custom-components/search-page/search-page.component';
import { SpinnerComponent } from 'src/app/shared/custom-components/spinner/spinner.component';
import { DetalheProdutoComponent } from '../consulta-produtos/detalhe-produto/detalhe-produto.component';
import { AlterarProdutoComponent } from '../consulta-produtos/alterar-produto/alterar-produto.component';
import { MatChipInputEvent } from '@angular/material/chips';
import { Cliente } from 'src/app/consts/cliente';
import { CategoriasService } from 'src/app/services/categorias.service';

@Component({
  selector: 'app-consulta-categorias',
  templateUrl: './consulta-categorias.component.html',
  styleUrls: ['./consulta-categorias.component.css']
})
export class ConsultaCategoriasComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
    filtrosExpanded = true;
    filtrosPesquisa: FormGroup
    dataSourceProdutos: MatTableDataSource<ListaProdutos>;
    produtosColumns = ['Categoria', 'Ncm', 'Detalhe'];
    selectStatus = [{Id: null, Nome: null}, {Id: 0, Nome: 'Revisão pendente'},{Id: 2, Nome:'Atualização Pendente'}, {Id: 1, Nome:'Revisado'}, {Id: 3, Nome:'Bloqueado'}]
  
    //MatChips
    //mat chips
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true
    readonly separatorKeysCodes = [ENTER, COMMA] as const
    palavrasChaves: string[] = []
    usuarioLogado;
    public idClienteUsuario

  constructor(public formBuilder: FormBuilder,
      public consultaService: ConsultaProdutoService,
      public categoriaService: CategoriasService,
      public dialog: MatDialog,
      public usuarioService: UsuarioService) {
  
      this.idClienteUsuario = JSON.parse(localStorage.getItem('usuarioCompleto'))
  
      this.filtrosPesquisa = this.formBuilder.group({
        Nome: [''],
        Ncm: ['']
      })
    }
  
    ngOnInit() {
        if(this.idClienteUsuario != 0){
          this.filtrosPesquisa.patchValue({
            idCliente: this.idClienteUsuario
          })
        }
        this.filtroValueChanges()
    }
  
    filtroValueChanges(){
      this.filtrosPesquisa.valueChanges.forEach(a => {
        this.filtrosPesquisa.updateValueAndValidity()
      })
    }
  
    async getUsuarioLogado(){
      await firstValueFrom(this.usuarioService.getLoggedUser())
      .then(response => {
        this.usuarioLogado = response[0]
        if(this.usuarioLogado.idCliente != 0){
          this.filtrosPesquisa.patchValue({
            idCliente: this.usuarioLogado.idCliente
          })
        }
      })
    }
  
    async getTabela(){
      const dialogRef = this.dialog.open(SpinnerComponent, {panelClass: 'spinner'})
      firstValueFrom(this.categoriaService.getConsultaCategoria(this.filtrosPesquisa.value))
      .then((response): any => {
        this.gerarDataSource(response)
        dialogRef.close()
      })
    }
  
    formatObjetoPesquisar(filtro){
      let objeto = {
        idCliente: this.filtrosPesquisa.value.idCliente,
        Produtos: this.palavrasChaves,
        idCategoria: this.filtrosPesquisa.value.idCategoria,
        Codigo: this.filtrosPesquisa.value.Codigo,
        Ncm: this.filtrosPesquisa.value.Ncm,
        DataInicio: this.filtrosPesquisa.value.DataInicio,
        DataFim: this.filtrosPesquisa.value.DataFim,
        Status: this.filtrosPesquisa.value.Status
      }
      return objeto
    }
  
    apagarCampoCliente(){
      this.filtrosPesquisa.patchValue({
        idCliente: null,
        Cliente: null
      })
    }
  
    pesquisarCategorias() {
      const dialogConfig = new MatDialogConfig();
  
      dialogConfig.data = {
        tabela: 'Categorias',
      };
  
      this.dialog
        .open(SearchPageComponent, dialogConfig)
        .afterClosed()
        .subscribe((categoriaEscolhida) => {
          if (categoriaEscolhida) {
           this.filtrosPesquisa.patchValue({
            Nome: categoriaEscolhida.Nome,
            Ncm: categoriaEscolhida.Ncm
           })
          }
        });
    }
  
    abrirDetalhe(produto){
      const dialogConfig = new MatDialogConfig();
  
      dialogConfig.data = {
        Produto: produto
      }
  
      dialogConfig.width = '90vw';
      dialogConfig.height = '90vh';
  
      this.dialog.open(DetalheProdutoComponent, dialogConfig)
    }
  
    abrirReclassificar(produto){
      const dialogConfig = new MatDialogConfig();
  
      dialogConfig.data = {
        Produto: produto
      }
  
      dialogConfig.width = '90vw';
  
      this.dialog.open(AlterarProdutoComponent, dialogConfig)
    }
  
    apagarCampoCategoria(){
      this.filtrosPesquisa.patchValue({
        idCategoria: '00000000-0000-0000-0000-000000000000',
        Categoria: null
      })
    }
  
    gerarDataSource(categorias): void{
      this.dataSourceProdutos = new MatTableDataSource(categorias)
      this.dataSourceProdutos.paginator = this.paginator
    }
  
    add(event: MatChipInputEvent): void {
      const input = event.input;
      const value = event.value
      if(this.palavrasChaves.length < 5){
        if((value || '').trim()){
          this.palavrasChaves.push(value)
  
          this.filtrosPesquisa.patchValue({
            Produtos: this.palavrasChaves
          })
        }
      }
  
      if(input){
        input.value = ''
      }
    }
  
    remove(palavra: string): void {
      const index = this.palavrasChaves.indexOf(palavra);
      if(index >= 0){
        this.palavrasChaves.splice(index, 1);
        if(this.palavrasChaves.length == 0){
          this.filtrosPesquisa.patchValue({
            Produtos: null
          })
        }
      }
    }
  
    limparDataInicio(){
      this.filtrosPesquisa.patchValue({
        DataInicio: null
      })
    }
  
    limparDataFim(){
      this.filtrosPesquisa.patchValue({
        DataFim: null
      })
    }
  
    limparFiltro(){
      this.palavrasChaves = []
      if(this.idClienteUsuario == 0){
        this.filtrosPesquisa.patchValue({
          Nome: '',
          Ncm: '',
        })
      }
      else{
        this.filtrosPesquisa.patchValue({
          Nome: '',
          Ncm: '',
       
        })
      }
  
      this.dataSourceProdutos = new MatTableDataSource()
      this.dataSourceProdutos.paginator = this.paginator
    }

}
