import { PagesGuard } from './../pages-guard.guard';
import { ConsultaProdutosComponent } from './consulta-produtos/consulta-produtos.component';
import { RevisaoComponent } from './revisao/revisao.component';
import { CalculoCustoComponent } from './calculo-custo/calculo-custo.component';
import { OperacoesComponent } from './operacoes.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConsultaCategoriasComponent } from './consulta-categorias/consulta-categorias.component';


const routes: Routes = [
  {
    path: '',
    component: OperacoesComponent,
    children: [
      {
        path: 'consulta-de-produtos',
        component: ConsultaProdutosComponent
      },
      {
        path: 'revisao',
        component: RevisaoComponent,
        canActivate: [PagesGuard]
      },
      {
        path: 'calculo-custo',
        component: CalculoCustoComponent
      },
      {
        path: 'consulta-de-categorias',
        component: ConsultaCategoriasComponent
      }
    ]
  }
]
@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ]
})
export class SegurancaRoutingModule { }

export const routedComponents = [

]
