import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { firstValueFrom, Subscription } from 'rxjs';
import { MensagemResposta } from 'src/app/consts/mensagem';
import { ImagemCarrosselService } from 'src/app/services/imagem-carrossel.service';
import { ErrorPageComponent } from 'src/app/shared/custom-components/error-page/error-page.component';
import { SpinnerComponent } from 'src/app/shared/custom-components/spinner/spinner.component';

export interface Imagem {
  Id: number;
  Imagem: String;
  Url: String;
  NomeImagem: String;
}

@Component({
  selector: 'app-imagens-carrosel',
  templateUrl: './imagens-carrosel.component.html',
  styleUrls: ['./imagens-carrosel.component.css']
})
export class ImagensCarrosselComponent implements OnInit, OnDestroy {

  form: FormGroup;
  imagens: Imagem[] = []
  selectedFile: File | null = null;
  previewUrl: string | ArrayBuffer | null = null;
  blobData: Blob | null = null;
  base64Data: string | null = null;
  ImagensSub: Subscription;
  phoneNumber: String = '5521998542805'

  constructor(private imagemService: ImagemCarrosselService,
    private fb: FormBuilder,
    private dialog: MatDialog
  ) {
    this.form = this.fb.group({
      Id: [0],
      Imagem: [null, Validators.required],
      Url: [''],
      NomeImagem: ['', Validators.required]
    })

  }

  ngOnInit(): void {
    this.ImagensSub = this.imagemService.CarrosselSub.subscribe((response: any) => {
      this.imagens = response
    })
  }

  ngOnDestroy(): void {

  }

  getTodos() {
    firstValueFrom(this.imagemService.getTodos())
      .then((response: Imagem[]) => {
        console.log(response)
        this.imagens = response
      })
  }

  post(corpo: any) {
    const dialogRef = this.dialog.open(SpinnerComponent, { panelClass: 'spinner' })
    firstValueFrom(this.imagemService.post(corpo))
      .then((response: MensagemResposta) => {
        if (response) {
          this.abrirPaginaErro('sistema', response);
          if (response[0].Codigo == 'CRM00001') {
            this.limpar();
          }
          dialogRef.close();
        }
      });
  }

  limpar() {
    this.form.patchValue({
      Id: 0,
      Imagem: null,
      Url: '',
      NomeImagem: '',
    })
  }

  delete(id: number) {
    const dialogRef = this.dialog.open(SpinnerComponent, { panelClass: 'spinner' })
    firstValueFrom(this.imagemService.delete(id))
      .then((response: MensagemResposta) => {
        if (response) {
          this.abrirPaginaErro('sistema', response);
          if (response[0].Codigo == 'CRM00003') {
            this.limpar();
          }
          dialogRef.close();
        }
      });
  }

  // Quando o usuário seleciona um arquivo
  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input.files && input.files[0]) {
      this.selectedFile = input.files[0];

      // Gerar a URL de pré-visualização
      const reader = new FileReader();
      reader.onload = (e) => {
        this.previewUrl = e.target?.result;
      };
      reader.readAsDataURL(this.selectedFile);

      // Converter o arquivo em um Blob
      this.convertToBlob(this.selectedFile);
    }
  }

  // Função para converter o arquivo em Blob
  private convertToBlob(file: File): void {
    const blob = new Blob([file], { type: file.type });
    this.blobData = blob;

    // Converter Blob para Base64
    this.convertBlobToBase64(blob);
  }

  // Converter Blob para Base64
  private convertBlobToBase64(blob: Blob): void {
    const reader = new FileReader();
    reader.onloadend = () => {
      this.base64Data = reader.result as string;
      console.log('Base64 gerado:', this.base64Data);
      this.imagens.unshift({
        Id: 0,
        Imagem: this.base64Data,
        Url: this.form.value.Url,
        NomeImagem: this.form.value.NomeImagem
      })
      console.log(this.imagens)
      this.imagemService.setCarrossel(this.imagens)
    };

    reader.readAsDataURL(blob); // Lê o Blob como Base64
  }

  private reset(): void {
    this.selectedFile = null;
    this.previewUrl = null;
    this.blobData = null;
    this.base64Data = null;
  }

  addNovaImagem() {
    document.getElementById("fileInput").click()
  }

  verificaArray() {
    return !this.imagens.some(response => response.Id == 0)
  }

  removerImagem() {
    this.imagens.pop()
    this.imagemService.setCarrossel(this.imagens)
  }

  abrirPaginaErro(tipo, mensagem) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      tipo: tipo,
      mensagem: mensagem,
    };

    this.dialog.open(ErrorPageComponent, dialogConfig);
  }

  public floatWhatsApp(){
    const whatsappUrl = `https://wa.me/${this.phoneNumber}`;
    window.open(whatsappUrl, '_blank');
  }
}
