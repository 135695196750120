import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment.hmr';
import { Imagem } from '../pages/cadastros/imagens-carrosel/imagens-carrosel.component';

@Injectable({
  providedIn: 'root'
})
export class ImagemCarrosselService {

  private API = environment.API
  public CarrosselSub = new BehaviorSubject<any>([])
  public emitCarrossel$ = this.CarrosselSub.asObservable();

  constructor(private http: HttpClient) { }

  getTodos(): Observable<Object>{
    return this.http.get(`${this.API}ImagensCarrossel`)
  }

  get(id: number): Observable<Object>{
    return this.http.get(`${this.API}ImagensCarrossel/${id}`)
  }

  post(corpo: Imagem[]): Observable<Object>{
    return  this.http.post(`${this.API}ImagensCarrossel`, corpo)
  }

  delete(id: number): Observable<Object>{
    return this.http.get(`${this.API}ImagensCarrosel`)
  }

  setCarrossel(imagens: Imagem[]){
    this.CarrosselSub.next(imagens)
  }

}
