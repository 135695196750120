import { ConteudoTributacaoComponent } from './conteudo-tributacao/conteudo-tributacao.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatChipsModule} from '@angular/material/chips';
import { FormularioModule } from './../../formulario/formulario.module';
import { MomentPipe } from './../../pipes/Moment.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxMaskModule } from 'ngx-mask';
import { MatGridListModule } from '@angular/material/grid-list';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { CadastroNcmComponent } from './cadastro-ncm/cadastro-ncm.component';
import { CategoriasComponent } from './categorias/categorias.component';
import { SistemasComponent } from './sistemas/sistemas.component';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ClientesComponent } from './clientes/clientes.component';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import { CadastrosRoute } from './cadastros-routing.module';
import { CadastrosComponent } from './cadastros.component';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import { AuthGuard } from 'src/app/auth/guards';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { MY_DATE_FORMAT } from 'src/app/consts';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { ImagensCarrosselComponent } from './imagens-carrosel/imagens-carrosel.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbdCarouselConfig } from '../dashboard/carousel/carousel-config';
import { CarouselConfigModule } from '../dashboard/carousel/carousel-config.module';

const materialModules = [
  MatCardModule,
  MatGridListModule,
  MatFormFieldModule,
  MatInputModule,
  MatIconModule,
  MatSelectModule,
  MatTableModule,
  MatButtonModule,
  MatDialogModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatExpansionModule,
  MatCheckboxModule,
  ReactiveFormsModule,
  FormsModule,
  MatDividerModule,
  MatChipsModule,
  MatSlideToggleModule,
]

const routeModules = [
  RouterModule,
  CadastrosRoute,
]

const internalComponents = [
  CadastrosComponent,
  ClientesComponent,
  SistemasComponent,
  CategoriasComponent,
  CadastroNcmComponent,
  UsuariosComponent,
  ConteudoTributacaoComponent,
  ImagensCarrosselComponent
]

@NgModule({
  declarations: [
    ...internalComponents,
  ],
  imports: [
    CommonModule,
    PipesModule,
    SharedModule,
    ReactiveFormsModule,
    FormularioModule,
    FormsModule,
    NgxMaskModule.forChild(),
    CurrencyMaskModule,
    NgbModule,
    CarouselConfigModule,
    ...materialModules,
    ...routeModules,
  ],
  providers: [
    AuthGuard,
    DatePipe,
    {provide: DateAdapter,
     useClass: MomentDateAdapter,
     deps: [MAT_DATE_LOCALE]},
     {
      provide: MAT_DATE_FORMATS,
      useValue: MY_DATE_FORMAT
     },
    MomentPipe,
    NgbdCarouselConfig
  ],
  bootstrap: [NgbdCarouselConfig]
})
export class CadastrosModule { }



