<form [formGroup]="form">
    <mat-card>
        <mat-card-header>
            <mat-card-title class="matCard-Font">Cadastro de imagens</mat-card-title>
        </mat-card-header>
        <br>
        <mat-card-content>
            <div class="row">
                <div class="col-12 col-sm-9">
                    <mat-form-field style="width: 100%;" appearance="outline">
                        <mat-label>Descricao</mat-label>
                        <input matInput formControlName="NomeImagem">
                    </mat-form-field>
                </div>

                <div class="col-sm-3 col-12"><button class="format-button" style="width: 100%;" mat-raised-button
                        (click)="addNovaImagem()" [disabled]="form.value.NomeImagem == '' && form.value.Url == ''">
                        <mat-icon>add</mat-icon>
                        Adicionar
                        nova imagem</button>
                </div>

                <input type="file" id="fileInput" (change)="onFileSelected($event)" accept="image/*" hidden>
            </div>

            <div class="row">
                <mat-form-field appearance="outline">
                    <mat-label>Url (link)</mat-label>
                    <input matInput formControlName="Url">
                </mat-form-field>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card>
        <div class="col-12 col-sm-12">
            <div class="row">
                <div class="col-12 col-sm -9">
                    <mat-card-title class="matCard-Font">Carrossel Preview</mat-card-title>
                </div>
                <div class="col-12 col-sm-3 justify-content-end">
                    <button style="width: 100%;" mat-raised-button class="format-button" color="warn"
                        (click)="removerImagem()"><mat-icon>delete</mat-icon> Remover Imagem</button>
                </div>
            </div>

            <br>
            <ngbd-carousel-config style="border-radius: 20px;"></ngbd-carousel-config>
        </div>
    </mat-card>

    <app-crud-buttons [showPost]="form.value.Id == 0" [showDelete]="false" [showPut]="false" [labelBotaoPost]="'Gravar'"
        [labelBotaoPut]="'Gravar'" [labelBotaoAuxUm]="'Limpar formulário'" [disablePost]="verificaArray()"
        [disableDelete]="form.value.Id == 0" (OnClickButtonPost)="post(imagens)" (OnClickButtonAuxUm)="limpar()"
        (OnClickButtonDelete)="delete(form.value.Id)">
    </app-crud-buttons>
</form>

<div class="floating-button" (click)="floatWhatsApp()">
    <i class="material-icons">add</i> <!-- Ícone (opcional) -->
</div>