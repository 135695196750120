import { Component, OnDestroy } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { ImagemCarrosselService } from 'src/app/services/imagem-carrossel.service';
import { Imagem } from '../../cadastros/imagens-carrosel/imagens-carrosel.component';

@Component({
  selector: 'ngbd-carousel-config',
  templateUrl: './carousel-config.html',
  providers: [NgbCarouselConfig]  // add NgbCarouselConfig to the component providers
})
export class NgbdCarouselConfig implements OnDestroy{

  images: String[] = [700, 533, 807, 124].map((n) => `https://picsum.photos/id/${n}/900/500`);
  ImagensSub: Subscription

  constructor(private config: NgbCarouselConfig,
    private imagemService: ImagemCarrosselService
  ) {
    // customize default values of carousels used by this component tree
    config.interval = 10000;
    config.wrap = true;
    config.keyboard = false;
    config.pauseOnHover = true;

    this.getImages();

    this.ImagensSub = this.imagemService.CarrosselSub.subscribe((response: any) => {
      console.log(this.mapearStringImagem(response))
      this.images = this.mapearStringImagem(response)
    })
  }

  ngOnDestroy(): void {
    this.ImagensSub.unsubscribe()
  }

  navigate(id){
    console.log(`Click: ${id}`)
  }

  getImages(){
    firstValueFrom(this.imagemService.getTodos())
    .then((response: Imagem[]) => {
      console.log(response)
      this.imagemService.setCarrossel(response)
    })
  }

  mapearStringImagem(Imagens: Imagem[]): String[]{
    let strings: String[] | File[]
    strings = Imagens.map(item => item.Imagem)
    return strings
  }
}