<form [formGroup]="filtrosPesquisa">
    <div style="margin: 15px;">
        <mat-accordion displayMode="default" [multi]="true" [hideToggle]="false">
            <mat-expansion-panel [hideToggle]="false" [expanded]="filtrosExpanded">
                <mat-expansion-panel-header>Consulta de Categorias</mat-expansion-panel-header>

                <div class="row">
                    <div class="col-sm-6 col-12">
                        <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                            <mat-label>Nome</mat-label>
                            <input matInput formControlName="Nome">
                            <button *ngIf="filtrosPesquisa.value.Cliente == null" mat-button mat-icon-button matSuffix
                                aria-label="Clear" (click)="pesquisarCategorias()">
                                <mat-icon>search</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>

                    <div class="col-sm-6 col-12">
                        <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                            <mat-label>NCM</mat-label>
                            <input matInput formControlName="Ncm">
                        </mat-form-field>
                    </div>
                </div>



                <div class="row justify-content-end" style="height: 10%;">
                    <div class="col-sm-2 col-12"><button class="format-button" mat-raised-button color="primary"
                            [disabled]="filtrosPesquisa.status == 'INVALID'" (click)="getTabela()">Pesquisar</button>
                    </div>
                    <div class="col-sm-2 col-12"><button class="format-button" mat-raised-button
                            (click)="limparFiltro()">Limpar
                            Filtro</button></div>
                </div>
                
            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <mat-card>
        <table style="width: 100%; height: auto;" mat-table [dataSource]="dataSourceProdutos" multiTemplateDataRows>
            <ng-container matColumnDef="Categoria">
                <th class="w-20" mat-header-cell *matHeaderCellDef>Categoria</th>
                <td mat-cell *matCellDef="let element">{{element.Nome}}</td>
            </ng-container>

            <ng-container matColumnDef="Ncm">
                <th class="w-20" mat-header-cell *matHeaderCellDef>Ncm</th>
                <td mat-cell *matCellDef="let element">{{element.Ncm}}</td>
            </ng-container>
            <ng-container matColumnDef="Detalhe">
                <th class="w-10" mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element"><button mat-raised-button color="primary"
                        (click)="abrirDetalhe(element)">Detalhe</button></td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="produtosColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: produtosColumns"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10,25,100]" aria-label="Selecione nº de Clientes"></mat-paginator>
    </mat-card>
</form>