import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbdCarouselConfig } from './carousel-config';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    NgbdCarouselConfig
  ],
  imports: [
    CommonModule,
    NgbModule
  ],
  exports: [NgbdCarouselConfig],
  bootstrap:[NgbdCarouselConfig],
  providers: [NgbdCarouselConfig]
})
export class CarouselConfigModule { }
